import { Car } from '../types/Veiculo';

const apiUrl = process.env.REACT_APP_API_URL;

export const searchAllVehicles = async ({
  searchTerm,
  currentPage = 1,
  priceRange,
}: {
  searchTerm: string;
  currentPage: number;
  priceRange: number[];
}) => {
  const url = `${apiUrl}/veiculos?page=${currentPage}&search=${searchTerm}&min=${priceRange[0]}&max=${priceRange[1]}`;
  const response = await fetch(url);
  const resJson = await response.json();
  return resJson as Car[];
};

export const getVehicleBySug = async ({ slug }: { slug: string }) => {
  const url = `${apiUrl}/veiculos/slug/${slug}`;
  const response = await fetch(url);
  const resJson = await response.json();
  return resJson as Car;
};

export type StatusResponseType = {
  total: number;
  revendas: number;
  cidades: number;
  corolla: number;
  civic: number;
  hilux: number;
  compass: number;
  polo: number;
  hb20: number;
};

export const getApiStatus = async (): Promise<StatusResponseType> => {
  const url = `${apiUrl}/veiculos/stats`;
  const response = await fetch(url);
  const resJson = await response.json();
  return resJson as StatusResponseType;
};

export const sendMessage = async (mensagem: {
  name: string;
  email: string;
  phone: string;
  message: string;
}) => {
  const url = `${apiUrl}/mensagem`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(mensagem),
  });
  const resJson = await response.json();
  console.log('res mensagem', resJson);
  return resJson;
};
