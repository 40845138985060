import React, { useEffect } from 'react';
import './SearchPage.css';
import InputSearch from '../../../components/InputSearch/InputSearch';
import Card from '../../../components/card/Card';
import Loader from '../../../components/Loader/Loader';
import { MdOutlineDownloading } from 'react-icons/md';
import PriceFilter from '../../../components/PriceFilter/PriceFilter';
import { useVehicles } from '../useVehicles';
import { Car } from '../../../types/Veiculo';
import { useSelector } from 'react-redux';

const SearchPage = () => {
  const {
    onSubmitSearch,
    vehicles,
    isLoading,
    showLoadMore,
    onLoadMore,
    triggerSearch,
    onChangePrice,
  } = useVehicles();

  const { searchTerm, priceFilter } = useSelector(
    (state: any) => state.vehicles
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className='container'>
        <h4>Exibindo resultados</h4>
        <InputSearch
          onSubmitHandler={onSubmitSearch}
          initialTerm={searchTerm}
        />
        <PriceFilter onChange={onChangePrice} initialValues={priceFilter} />
        <div className='search-container'>
          {vehicles.map((vehicle: Car, index: number) => (
            <Card car={vehicle} key={index} />
          ))}
        </div>
        {isLoading && (
          <div className='loader-container'>
            <Loader />
          </div>
        )}
        {showLoadMore() && (
          <div className='vermais-container flex-center'>
            <button
              className='button button-secondary button-big'
              onClick={onLoadMore}
            >
              {isLoading && <Loader />}
              {!isLoading && <MdOutlineDownloading size='18px' />}
              Ver Mais
            </button>
          </div>
        )}
      </div>
      {vehicles.length > 0 && (
        <div className='counter'>Exibindo {vehicles.length} resultados...</div>
      )}
    </>
  );
};

export default SearchPage;
