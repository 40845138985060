import React, { useEffect, useState } from 'react';
import './VehicleDetails.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Car } from '../../../types/Veiculo';
import { getVehicleBySug } from '../../../api/api.thunk';
import {
  FaInstagram,
  FaFacebook,
  FaWhatsapp,
  FaMapMarkedAlt,
  FaMapMarker,
  FaPhone,
  FaLongArrowAltLeft,
  FaHeart,
  FaRegHeart,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { TbExternalLink } from 'react-icons/tb';
import useGeolocation from '../../../utils/useGeolocation';
import { calculateDistance } from '../../../utils/calculateDistance';
import { FaHeartCirclePlus } from 'react-icons/fa6';
import Maps from '../../../components/Maps/Maps';

const VehicleDetailsPage = () => {
  const navigate = useNavigate();
  const userLocation = useGeolocation();
  const [carInfo, setCarInfo] = useState<Car | null>(null);
  const [preferred, setPreferred] = useState(false);
  const { slug } = useParams();
  const location = useLocation();
  const car = location.state?.car;

  const fetchData = async (slug: string) => {
    const res = await getVehicleBySug({ slug });
    console.log('res slug', res);
    setCarInfo(res);
  };

  const onNavigateHandler = (link: string) => {
    window.open(link, '_blank');
  };

  const getDistanceKM = (coordinates: {
    latitude: number;
    longitude: number;
  }) => {
    const { latitude, longitude } = coordinates;
    if (!latitude && longitude) {
      return null;
    }
    const distanceKM = calculateDistance(
      userLocation.latitude as number,
      userLocation.longitude as number,
      latitude,
      longitude
    );

    return Math.round(distanceKM);
  };

  const onChangePreferred = (event: React.MouseEvent) => {
    event.stopPropagation();

    const favoriteCars = JSON.parse(
      localStorage.getItem('favoriteCars') || '[]'
    );
    if (preferred) {
      // Remover carro dos favoritos
      const updatedFavorites = favoriteCars.filter(
        (favCar: Car) => favCar.slug !== car.slug
      );
      localStorage.setItem('favoriteCars', JSON.stringify(updatedFavorites));
    } else {
      // Adicionar carro aos favoritos
      favoriteCars.push(car);
      localStorage.setItem('favoriteCars', JSON.stringify(favoriteCars));
    }

    setPreferred((prevPreferred) => !prevPreferred);
  };

  useEffect(() => {
    if (car) {
      setCarInfo(car);
    } else if (slug) {
      fetchData(slug);
    }
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (carInfo) {
      const favoriteCars = JSON.parse(
        localStorage.getItem('favoriteCars') || '[]'
      );
      console.log('carInfo.slug', carInfo.slug);
      const isFavorite = favoriteCars.some(
        (favCar: Car) => favCar?.slug === carInfo.slug
      );
      setPreferred(isFavorite);
    }
  }, [carInfo]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {!!carInfo && (
        <>
          {/* <div className="container">
                        <div className="details-action">
                            <button className="btn-icon"><FaLongArrowAltLeft /></button>
                            <h2>Detalhes</h2>
                            <button className="btn-icon" onClick={onChangePreferred}>
                                {!preferred && <FaRegHeart />}
                                {preferred && <FaHeart />}
                            </button>
                        </div>
                    </div> */}
          <div className='container details-container'>
            <div className='details-content details-car'>
              <img src={carInfo.image} alt={carInfo.name} />

              <div className='details-car-content'>
                <h2 className='name'>{carInfo.name}</h2>
                <h4 className='year'>{carInfo.year}</h4>
                <h3 className='price'>
                  {carInfo.price?.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </h3>
                <button
                  className='button button-primary button-big'
                  onClick={() => onNavigateHandler(carInfo.link)}
                  style={{ width: '100%' }}
                >
                  <TbExternalLink /> Ver mais detalhes
                </button>
                <button
                  className='btn-icon details-heart'
                  onClick={onChangePreferred}
                >
                  {!preferred && <FaRegHeart />}
                  {preferred && <FaHeart />}
                </button>
                <button
                  className='btn-icon details-back'
                  onClick={handleGoBack}
                >
                  <FaLongArrowAltLeft />
                </button>
              </div>
            </div>
            <div className='details-content details-shop'>
              <h2>{carInfo.shop.name}</h2>
              <h4>
                <FaMapMarkedAlt /> {carInfo.shop.city} - {carInfo.shop.state}
              </h4>
              {!!carInfo.shop.coordinates && (
                <h4>
                  <FaMapMarker /> {getDistanceKM(carInfo.shop.coordinates)} Km
                </h4>
              )}
              <h4>
                <FaPhone /> {carInfo.shop.whatsapp}
              </h4>
              <h5>
                <Link to={carInfo.shop.website}>{carInfo.shop.website}</Link>
              </h5>
              <div className='shop-details-social'>
                {!!carInfo.shop.instagram && (
                  <FaInstagram
                    onClick={() =>
                      onNavigateHandler(carInfo.shop.instagram as string)
                    }
                  />
                )}
                {!!carInfo.shop.facebook && (
                  <FaFacebook
                    onClick={() =>
                      onNavigateHandler(carInfo.shop.facebook as string)
                    }
                  />
                )}
              </div>
              <button className='button whatsapp-btn'>
                <FaWhatsapp /> WhatsApp
              </button>
            </div>
          </div>
          <div className='container'>
            {!!carInfo.shop.coordinates && (
              <Maps
                position={{
                  lat: carInfo.shop.coordinates.latitude,
                  lng: carInfo.shop.coordinates.longitude,
                }}
              />
            )}
          </div>
          <div className='container'>
            <div className='details-content terms-content'>
              <p>
                Todo o conteúdo apresentado em nosso site é fornecido por
                terceiros e meramente listamos os veículos disponíveis. Não
                participamos diretamente de nenhuma fase da negociação ou venda.
                Assim, todas as negociações devem ser realizadas diretamente na
                empresa de origem do anúncio. Certifique-se de verificar as
                condições e detalhes do veículo diretamente com o vendedor antes
                de efetuar qualquer transação.
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default VehicleDetailsPage;
