import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Car } from '../../types/Veiculo';

interface VehiclesState {
  currentPage: number;
  isLoading: boolean;
  searchTerm: string;
  vehicles: Car[];
  priceFilter: number[];
  isLoaded: boolean;
}

const initialState: VehiclesState = {
  currentPage: 1,
  isLoading: false,
  searchTerm: '',
  vehicles: [],
  priceFilter: [0, 500],
  isLoaded: false,
};

const vehiclesSlice = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setVehicles: (
      state,
      action: PayloadAction<{ data: Car[]; newPage: number }>
    ) => {
      state.vehicles =
        action.payload.newPage === 1
          ? action.payload.data
          : [...state.vehicles, ...action.payload.data];
      state.currentPage = action.payload.newPage;
    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setPriceFilter: (state, action: PayloadAction<number[]>) => {
      state.priceFilter = action.payload;
    },
    setIsLoaded: (state, action: PayloadAction<boolean>) => {
      state.isLoaded = action.payload;
    },
    resetState: (state) => {
      state.currentPage = initialState.currentPage;
      state.isLoaded = initialState.isLoaded;
      state.isLoading = initialState.isLoading;
      state.priceFilter = initialState.priceFilter;
      state.searchTerm = initialState.searchTerm;
      state.vehicles = initialState.vehicles;
    },
  },
});

export const {
  setLoading,
  setVehicles,
  setSearchTerm,
  setCurrentPage,
  setPriceFilter,
  setIsLoaded,
  resetState,
} = vehiclesSlice.actions;

export default vehiclesSlice;
