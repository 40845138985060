import React, { useCallback, useEffect } from 'react';
import { Car } from '../../types/Veiculo';
import { useLocation } from 'react-router-dom';
import { searchAllVehicles } from '../../api/api.thunk';
import { useDispatch, useSelector } from 'react-redux';

import {
  setLoading,
  setVehicles,
  setSearchTerm,
  setCurrentPage,
  setPriceFilter,
  setIsLoaded,
} from '../Vehicles/VehiclesSlice';

export type VehiclesContext = {
  onSubmitSearch: (term: string) => void;
  vehicles: Car[];
  isLoading: boolean;
  showLoadMore: () => boolean;
  onLoadMore: () => void;
  triggerSearch: () => void;
  onChangePrice: (values: number[]) => void;
};

export const useVehicles = () => {
  const location = useLocation();

  const {
    searchTerm,
    currentPage,
    priceFilter,
    vehicles,
    isLoading,
    isLoaded,
  } = useSelector((state: any) => state.vehicles);

  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    if (isLoaded) return;
    dispatch(setLoading(true));
    try {
      const newData = await searchAllVehicles({
        searchTerm,
        currentPage,
        priceRange: priceFilter,
      });
      dispatch(setVehicles({ data: newData, newPage: currentPage }));
    } catch (error) {
      console.error('Fetch data error:', error);
    } finally {
      updateSearchTermInURL();
      dispatch(setLoading(false));
      dispatch(setIsLoaded(true));
    }
  }, [searchTerm, currentPage, isLoaded, priceFilter, dispatch]);

  const showLoadMore = () => {
    return vehicles.length > 0 && vehicles.length % 30 === 0;
  };

  const onLoadMore = async () => {
    dispatch(setCurrentPage(currentPage + 1));
    dispatch(setIsLoaded(false));
  };

  const onSubmitSearch = (term: string) => {
    dispatch(setSearchTerm(term));
    dispatch(setCurrentPage(1));
    dispatch(setIsLoaded(false));
  };

  const updateSearchTermInURL = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('termo', searchTerm);
    queryParams.set('page', currentPage);
    queryParams.set('min', priceFilter[0]);
    queryParams.set('max', priceFilter[1]);
    const newURL = `${location.pathname}?${queryParams.toString()}`;
    window.history.replaceState(null, '', newURL);
  };

  const triggerSearch = () => {
    const queryParams = new URLSearchParams(location.search);
    const queryTerm = queryParams.get('termo') || '';
    const queryMin = parseInt(queryParams.get('min') || '0');
    const queryMax = parseInt(queryParams.get('max') || '500');
    const queryPage = parseInt(queryParams.get('page') || '1');

    if (
      searchTerm !== queryTerm ||
      currentPage !== queryPage ||
      queryMin !== priceFilter[0] ||
      queryMax !== priceFilter[1]
    ) {
      dispatch(setSearchTerm(queryTerm));
      dispatch(setCurrentPage(1));
      dispatch(setPriceFilter([queryMin, queryMax]));
      dispatch(setCurrentPage(queryPage));
      dispatch(setIsLoaded(false));
    }
  };

  const onChangePrice = (values: number[]) => {
    dispatch(setCurrentPage(1));
    dispatch(setPriceFilter(values));
    dispatch(setIsLoaded(false));
  };

  useEffect(() => {
    triggerSearch();
    // updateSearchTermInURL();
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    onSubmitSearch,
    vehicles: vehicles,
    isLoading: isLoading,
    showLoadMore,
    onLoadMore,
    triggerSearch,
    onChangePrice,
  };
};
