import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import './App.css';
import HomePage from './pages/HomePage/HomePage';
import TermsAndConditionsPage from './pages/TermsAndConditions/TermsAndConditionsPage';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import { useEffect, useState } from 'react';
import HomePageMetaTag from './components/Metatags/HomePageMetaTag';
import VehicleDetailsPage from './pages/Vehicles/VehicleDetails/VehicleDetails';
import FavoritesPage from './pages/FavoritesPage/FavoritesPage';
import ContatoPage from './pages/Contato/ContatoPage';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Vehicles from './pages/Vehicles/Vehicles';
import { Provider } from 'react-redux';
import { store } from './store';

const AppRouter = () => {
  const location = useLocation();
  const [showNavbarLogo, setShowNavbarLogo] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      if (location.pathname === '/') {
        setShowNavbarLogo(position > 120);
      } else {
        setShowNavbarLogo(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Executa o handleScroll uma vez quando o componente é montado ou quando o pathname muda
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location.pathname]);

  return (
    <>
      <HomePageMetaTag />
      <Header showLogo={showNavbarLogo} />
      <div>
        <Provider store={store}>
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/veiculos/*' element={<Vehicles />} />
            <Route path='/favoritos' element={<FavoritesPage />} />
            <Route path='/termos-de-uso' element={<TermsAndConditionsPage />} />
            <Route path='/contato' element={<ContatoPage />} />
            <Route path='*' element={<HomePage />} />
          </Routes>
        </Provider>
      </div>
      <Footer />
    </>
  );
};

const App = () => {
  return (
    <Router>
      <AppRouter />
    </Router>
  );
};

export default App;
