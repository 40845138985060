import React, { useCallback, useEffect, useState } from 'react';
import { Range, getTrackBackground } from 'react-range';
import { debounce } from '../../utils/debounce';
import './PriceFilter.css';

type PriceFilterProps = {
  onChange: (value: number[]) => void;
  initialValues?: number[];
};

const PriceFilter = ({
  onChange,
  initialValues = [0, 500],
}: PriceFilterProps) => {
  const STEP = 10;
  const MIN = 0;
  const MAX = 500;

  const [values, setValues] = useState(initialValues);

  const debouncedSetRange = useCallback(
    debounce((value: number[]) => {
      onChange(value);
    }, 300),
    []
  );

  const handleRangeChange = (value: number[]) => {
    setValues(value);
    debouncedSetRange(value);
  };

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  return (
    <>
      <h5 style={{ margin: '0px' }}>Filtrar por valor</h5>
      <div className='price-container'>
        <Range
          values={values}
          step={STEP}
          min={MIN}
          max={MAX}
          rtl={false}
          onChange={handleRangeChange}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: '36px',
                display: 'flex',
                width: '100%',
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: '5px',
                  width: '100%',
                  borderRadius: '4px',
                  background: getTrackBackground({
                    values,
                    colors: ['#ccc', '#e7d000', '#ccc'],
                    min: MIN,
                    max: MAX,
                    rtl: false,
                  }),
                  alignSelf: 'center',
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged, index }) => (
            <div
              {...props}
              key={props.key}
              style={{
                ...props.style,
                height: '24px',
                width: '24px',
                borderRadius: '100px',
                backgroundColor: '#e7d000',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0px 2px 6px #AAA',
              }}
            ></div>
          )}
        />
        <div className='values-container'>
          <div>R$ {values[0]}.000,00</div>
          <div>R$ {values[1]}.000,00</div>
        </div>
      </div>
    </>
  );
};

export default PriceFilter;
